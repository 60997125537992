import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["add_item", "template"];

    add(event) {
        event.preventDefault();
        let content = this.templateTarget.innerHTML.replace(/TEMPLATE_ID/g, new Date().valueOf());
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    }

    destroy(event) {
        event.preventDefault();
        let item = event.target.closest(".linha-atividade");
        item.querySelector("input[name*='destroy']").value = true;
        item.style.display = 'none'
    }
}
