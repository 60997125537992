// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
global.$ = jQuery;

import "controllers";
import "bootstrap";
import "../stylesheets/application";

import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css")

require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require("jquery-validation")
require("jquery-validation/dist/localization/messages_pt_BR.min.js")

import autosize from 'autosize';

$(document).on('turbolinks:before-cache', function () {
    var dataTable = $('.data-table').DataTable();
    if (dataTable !== null) {
        dataTable.destroy();
        dataTable = null;
    }
});

$(document).on('ready turbolinks:load', function () {
    $('.data-table').DataTable({
        ordering: true,
        info: false,
        paging: false,
        stateSave: true,
        language: {"search": "Filtrar:"}
    });

    $(".datepicker").flatpickr({
    });

    autosize($('textarea'));

    $("#pitrit-form").validate()
    $("#regime-form").validate()
    $("#reducao-motivos-form").validate()
    $("#semestre-form").validate()
    $("#user-form").validate()

    $("#salvar-pitrit").click(function () {
        $("#pitrit-form").submit();
    });

    $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
    });
    
    $('[data-toggle="tooltip"]').on('mouseleave', function () {
        $(this).tooltip('hide');
    });
    
    $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide');
    });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
